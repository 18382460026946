import React from "react"
import { Card, Col, List, Row, Typography } from "antd"
import { RichText, RichTextBlock } from "prismic-reactjs"
import { graphql, Link } from "gatsby"
import { JsonDebugger } from "./json-debugger"
import { htmlSerializer } from "../utils/html-serializer"
// const { Item } = List
// const { Meta } = Item
const { Title, Paragraph } = Typography

interface ProductAnalysisProps {
  title?: string
  derivedFrom?: string | RichTextBlock[]
  analysis?: {
    percent: string
    element: {
      url: string
      uid: string
      document: {
        data: {
          name: string
          symbol: string
        }
      }
    }
  }[]
}

export const ProductAnalysis: React.FC<ProductAnalysisProps> = ({
  title = "Guaranteed Analysis",
  analysis,
  derivedFrom,
}) => {
  if (!analysis) {
    return null
  }
  // console.log({ derivedFrom })
  const derivedFromText = !Array.isArray(derivedFrom)
    ? derivedFrom
    : RichText.asText(derivedFrom)
  const colSpan = derivedFromText ? 12 : 24
  const elements = analysis
    .map(({ element, percent }) => {
      const { url, uid, document } = element
      const { name, symbol } = document?.data || {}
      const floatPercent = parseFloat(percent)
      const display =
        floatPercent > 1
          ? floatPercent.toFixed(2)
          : floatPercent >= 0.1
          ? floatPercent.toPrecision(2)
          : floatPercent
      return { name, symbol, url, uid, percent, displayPercent: display }
    })
    .filter(({ percent }) => !!percent)
    .sort((a, b) => {
      if (a.percent === b.percent) {
        return 0
      }
      return a.percent > b.percent ? -1 : 1
    })

  if (elements.length === 0 || derivedFromText === "") {
    return null
  }

  // return null

  const cardContent = (
    <List
      // itemLayout="horizontal"
      dataSource={elements}
      renderItem={(item, i) => (
        <List.Item>
          <List.Item.Meta
            title={
              <Link to={item.url}>
                {`${item.name}${item.symbol ? ` (${item.symbol})` : null}`}
              </Link>
            }
          />
          <div>{item.displayPercent}%</div>
        </List.Item>
      )}
    />
  )
  // console.log(List)
  return (
    <section>
      <Title level={2}>{title}</Title>
      <Row gutter={[24, 24]}>
        <Col xs={24} md={colSpan}>
          <Card title="Elements">
            {cardContent}
            {/* <JsonDebugger json={{ elements }} /> */}
          </Card>
        </Col>
        {derivedFromText && (
          <Col md={colSpan}>
            <Card title="Derived From">
              {/* <Title level={3}>Derived From</Title> */}
              <Paragraph>{derivedFromText}</Paragraph>
            </Card>
          </Col>
        )}
      </Row>
      {/* <RichText render={derivedFrom} htmlSerializer={htmlSerializer} /> */}
    </section>
  )
}

// GraphQL Query fragment for analysis information on a product
export const analysisQuery = graphql`
  fragment ProductAnalysis on PrismicProduct {
    data {
      analysis {
        percent
        element {
          url
          document {
            ... on PrismicChemicalElement {
              id
              url
              data {
                name
                symbol
              }
              uid
            }
          }
          uid
          slug
          id
        }
      }
    }
  }
`
