// extracted by mini-css-extract-plugin
export var container = "product-page-module--container--TOfQf";
export var productCarousel = "product-page-module--product-carousel--hl+NW";
export var content = "product-page-module--content--58d+B";
export var testimonialCarouselWrap = "product-page-module--testimonial-carousel-wrap--a54Vf";
export var testimonialCarouselItem = "product-page-module--testimonial-carousel-item--7PkaY";
export var reviewSection = "product-page-module--review-section--tVnJ8";
export var antTypography = "product-page-module--ant-typography--j0eJC";
export var productTitleCol1 = "product-page-module--product-title-col1--P2jcH";
export var productTitleCol2 = "product-page-module--product-title-col2--0DR+Q";
export var videoFrame = "product-page-module--video-frame--JiSjA";