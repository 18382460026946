import * as React from "react"
import {
  Row,
  Col,
  Carousel,
  Space,
  Button,
  Form,
  Select,
  InputNumber,
  Typography,
  CarouselProps,
  Tooltip,
} from "antd"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { AddToCart } from "./add-to-cart"
import {
  productCarousel,
  productTitleCol1,
  productTitleCol2,
  // mainProductImage,
} from "../templates/product-page.module.less"
import { content } from "./product-page-card-content.style.module.less"
import { StoreContext } from "../context/store-context"
import isEqual from "lodash.isequal"
import { formatPrice } from "../utils/format-price"
import { ChevronUpIcon } from "../icons/chevron-up"
import { ChevronDownIcon } from "../icons/chevron-down"
import { ChevronRightIcon } from "../icons/chevron-right"
import { ChevronLeftIcon } from "../icons/chevron-left"

interface ProductVariant {
  availableForSale: boolean
  title: string
  price: number
  id: string
  storefrontId: string
  barcode: string
  sku: string
  inventory_policy: string
  inventory_quantity: number
  selectedOptions?: { name: string; value: unknown }[]
}
interface ProductImageProps {
  id: string
  altText: string
  src: string
  main: IGatsbyImageData
  thumbnail: IGatsbyImageData
}
interface ProductCardContentProps {
  title: string
  images: ProductImageProps[]
  shopifyProductStorefrontId: string
  variants: ProductVariant[]
  initialVariantSku?: ProductVariant["sku"]
  options?: { id: string; name: string; values: string[] }[]
  //   videoWidth?: number
  //   videoHeight?: number
  videoElement?: React.ReactElement
  variantSelectCallback?: (variant: ProductVariant) => void
  priceCurrencyCode?: string
}
const { Title } = Typography

const slickCarouselSettings: CarouselProps = {
  //   accessibility: false,
  arrows: true,
  // dots: false,
  dots: true,
  infinite: true,
  autoplay: false,
  slidesToShow: 4,
  //   slidesToScroll: 1,
  vertical: true,
  verticalSwiping: true,
  dotPosition: "left",
  // dotPosition: "top",
  // nextArrow: i,
  // prevArrow: a,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        vertical: false,
        dots: true,
        // verticalSwiping: false,
      },
    },
  ],
}

// Create a carousel for the product images
const createProductThumbnailCarousel = (
  productTitle: string,
  images: ProductCardContentProps["images"],
  selectCallback: React.Dispatch<ProductImageProps>,
  slidesActiveOnLoad = 0
) => {
  return images.map((image, index) => (
    <div key={`product-image-${image.id}`}>
      <a
        href={image.src}
        onClick={(e) => {
          e.preventDefault()
          // console.log(image)
          selectCallback(image)
        }}
      >
        <GatsbyImage
          objectFit="contain"
          loading={index <= slidesActiveOnLoad ? "eager" : "lazy"}
          alt={
            image.altText
              ? image.altText
              : `Product Image of ${productTitle} #${index + 1}`
          }
          // src={image.src}
          image={image.thumbnail}
          //   className={productThumbnail}
        />
      </a>
    </div>
  ))
}

const createMainProductCarousel = (
  productTitle: string,
  images: ProductCardContentProps["images"]
) => {
  return images.map((image, index) => (
    <GatsbyImage
      key={image.id}
      objectFit="contain"
      loading={index === 0 ? "eager" : "lazy"}
      alt={
        image.altText
          ? image.altText
          : `Product Image of ${productTitle} #${index + 1}`
      }
      // src={image.src}
      image={image.main}
    />
  ))
}
export const ProductCardContent: React.FC<ProductCardContentProps> = ({
  title,
  images,
  variants = [],
  // variants: [initialVariant],
  //   videoWidth = 400,
  videoElement,
  initialVariantSku,
  options = [],
  variantSelectCallback,
  shopifyProductStorefrontId,
  priceCurrencyCode = "USD",
  //   videoHeight = 225,
}) => {
  const hasImages = images.length > 0
  const initialVariant = variants.sort((a, b) => a.price - b.price)[0]
  const initialVariantState = initialVariantSku
    ? variants.find(({ sku: vsku }) => vsku == initialVariantSku) || {
        ...initialVariant,
      }
    : { ...initialVariant }
  const [variant, setVariant] = React.useState(initialVariantState)
  const [selectedImage, setSelectedImage] = React.useState(images[0])
  const [quantity, setQuantity] = React.useState(1)
  const [price, setPrice] = React.useState(null)
  const [originalPrice, setOriginalPrice] = React.useState(null)
  const productImages = createProductThumbnailCarousel(
    title,
    images,
    setSelectedImage,
    slickCarouselSettings.slidesToShow
  )
  // console.log(initialVariant)
  const largeProductImages = createMainProductCarousel(title, images)
  const { client, customerAdjustedPrice } = React.useContext(StoreContext)
  const thumbnailCarousel = React.useRef(null)
  const mainImageCarousel = React.useRef(null)

  React.useEffect(() => {
    const newPrice = formatPrice(priceCurrencyCode, variant.price)
    setPrice(
      customerAdjustedPrice(variant.sku)
        ? formatPrice(priceCurrencyCode, customerAdjustedPrice(variant.sku))
        : newPrice
    )
    setOriginalPrice(newPrice)
  }, [variant, priceCurrencyCode, customerAdjustedPrice])

  const productVariant =
    variants.find((variant) => {
      return variant.selectedOptions.every((selectedOption) => {
        return options[selectedOption.name] === selectedOption.value.valueOf()
      })
    }) || variant

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
    // true
  )

  const handleOptionChange = (index, value) => {
    if (!value || value === "") {
      return
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setVariant({ ...selectedVariant })
    if (selectedVariant && variantSelectCallback /* selectedVariant.sku*/) {
      //   navigate(`${path}?sku=${selectedVariant.sku}`)
      variantSelectCallback(selectedVariant)
      // sendPageView()
    }
  }
  const checkAvailability = React.useCallback(
    (selectedVariantId) => {
      client.product
        .fetch(shopifyProductStorefrontId)
        .then((fetchedProduct) => {
          const result = fetchedProduct?.variants.find(
            (variant) => variant.id === selectedVariantId
          )
          const { available } = result

          // console.log("Filtered variants", { available }, result)
          setAvailable(available)
        })
        .catch((e) => {
          console.error("Error checking availability", e)
          setAvailable(false)
        })
    },
    [shopifyProductStorefrontId, client]
  )

  React.useEffect(() => {
    checkAvailability(productVariant.storefrontId)
  }, [productVariant.storefrontId, checkAvailability])

  return (
    <Row gutter={[24, 24]} className={content}>
      <Col xs={{ span: 24, order: 1 }} md={{ span: 0, order: 0 }}>
        <Title className={productTitleCol1}>{title}</Title>
      </Col>
      {hasImages && (
        <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
          <Row
            role="group"
            aria-label="gallery"
            aria-describedby="instructions"
            gutter={[6, 6]}
          >
            <Col
              style={{ width: "200px" }} // No idea why tis makes slick slider work
              xs={{ order: 1, span: 24 }}
              lg={{ order: 2, span: images.length > 1 ? 20 : 22 }}
            >
              <div style={{ maxWidth: "550px", minWidth: "240px" }}>
                <Carousel
                  asNavFor={thumbnailCarousel.current}
                  ref={(ref) => (mainImageCarousel.current = ref)}
                  slidesToShow={1}
                  focusOnSelect={true}
                  //   className="center"
                  // centerMode
                  infinite
                  // centerPadding="10px"
                >
                  {largeProductImages}
                </Carousel>
              </div>
              {images.length > 1 && (
                <Row justify="space-between" className="touch-nav">
                  <Col>
                    <Tooltip title="Previous">
                      <Button
                        // shape="circle"
                        type="text"
                        icon={<ChevronLeftIcon />}
                        size="large"
                        onClick={() => {
                          mainImageCarousel.current.prev()
                        }}
                      />
                    </Tooltip>
                  </Col>
                  <Col>
                    <Tooltip title="Next">
                      <Button
                        // shape="circle"
                        type="text"
                        icon={<ChevronRightIcon />}
                        size="large"
                        onClick={() => {
                          mainImageCarousel.current.next()
                        }}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              )}
            </Col>
            {images.length > 1 && (
              <Col
                xs={0}
                md={{ order: 2, span: 24 }}
                lg={{ order: 1, span: 4 }}
              >
                <div className={productCarousel}>
                  <>
                    {/* <Button
                      icon={<ChevronUpIcon />}
                      onClick={() => {
                        thumbnailCarousel.current.prev()
                      }}
                      aria-hidden
                      block
                    ></Button> */}
                    <Carousel
                      asNavFor={mainImageCarousel.current}
                      ref={(ref) => (thumbnailCarousel.current = ref)}
                      {...slickCarouselSettings}
                      slidesToShow={Math.min(
                        slickCarouselSettings.slidesToShow,
                        images.length
                      )}
                      focusOnSelect={true}

                      //className={productCarousel}
                    >
                      {productImages}
                    </Carousel>
                    {/* <Button
                      icon={<ChevronDownIcon />}
                      onClick={() => {
                        thumbnailCarousel.current.next()
                      }}
                      aria-hidden="true"
                      block
                    ></Button> */}
                  </>

                  {/* {images.length > slickCarouselSettings.slidesToShow && (
                  <div id="instructions">
                    <Space size="small">
                      <Button
                        icon={<UpOutlined />}
                        onClick={() => {
                          carousel.current.prev()
                        }}
                        aria-hidden="true"
                      ></Button>
                      <Button
                        icon={<DownOutlined />}
                        onClick={() => {
                          carousel.current.next()
                        }}
                        aria-hidden="true"
                      ></Button>
                    </Space>
                  </div>
                )} */}
                </div>
              </Col>
            )}
          </Row>
        </Col>
      )}
      {!hasImages && <Col order={3}>No Preview image</Col>}
      <Col xs={24} md={12} order={4}>
        <span className={productTitleCol2}>
          <Title>{title}</Title>
        </span>
        <Space>
          {originalPrice !== price && (
            <Title level={3} delete disabled>
              {originalPrice}
            </Title>
          )}
          <Title level={2}>{price}</Title>
        </Space>
        <Row gutter={[24, 24]}>
          <Col>
            <Form
              layout={"inline"}
              initialValues={
                options.length > 0
                  ? options.reduce((p, { name, values: [firstValue] }) => {
                      const selectedVariantValue = (
                        variant.selectedOptions || []
                      ).find(({ name: vName }) => vName == name)?.value
                      return {
                        ...p,
                        [name]: selectedVariantValue || firstValue,
                      }
                    }, {})
                  : {}
              }
            >
              {options.length > 0 &&
                options.map(({ id, name, values }, index) => (
                  <Form.Item name={name} label={name} key={id}>
                    <Select
                      onChange={(event) => handleOptionChange(index, event)}
                      style={{ width: "120px" }}
                    >
                      {values.map((value, index) => (
                        <Select.Option
                          value={value}
                          aria-label={value}
                          key={index}
                        >
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ))}
              <Form.Item label="Quantity">
                <InputNumber
                  aria-label="Quantity"
                  onChange={(value) => setQuantity(value)}
                  defaultValue={quantity}
                  min={1}
                  //  max="20"
                />
              </Form.Item>
            </Form>
          </Col>
          <Col span={24}>
            <AddToCart
              variantId={productVariant.storefrontId}
              quantity={quantity}
              available={available}
              // available={true}
              block
            />
          </Col>
          <Col
            flex="auto"
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {videoElement}
          </Col>
          {/* <Col span={24}>
		  <List
			style={{ width: "100%" }}
			dataSource={[
			  {
				title: "Type",
				content: (
				  <Link to={category.url}>
					{RichText.asText(category.data.name.richText)}
				  </Link>
				),
			  },
			  product.tags && product.tags.length > 0
				? {
					title: "Tags",
					content: product.tags
					  ? product.tags.map((tag, i) => (
						  <Link to={`/search?t=${tag}`} key={i}>
							{tag}
						  </Link>
						))
					  : null,
				  }
				: null,
			].filter(Boolean)}
			renderItem={(item) => (
			  <List.Item>
				<List.Item.Meta
				  title={item.title}
				  description={item.content}
				/>
			  </List.Item>
			)}
		  />
		</Col> */}
        </Row>
      </Col>
    </Row>
  )
}
