import React from "react"
import {
  Avatar,
  Card,
  Col,
  Rate,
  Row,
  Space,
  Image,
  Typography,
  Modal,
} from "antd"
import { graphql, Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import { htmlSerializer } from "../utils/html-serializer"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import {
  testimonialCover,
  testimonialContentCompact,
} from "./testimonial-card.module.less"
import { StarFilled } from "@ant-design/icons"
import { JsonDebugger } from "./json-debugger"

const { Paragraph } = Typography

interface Review {
  rating: string
  review: string
}

export const ReviewCard: React.FC<{
  compact?: boolean
  review: {
    data: any
    url: string
    id: string
  }
}> = ({ review, compact = true }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }
  const hideModal = () => {
    setIsModalVisible(false)
  }

  // const firstImage =
  //   Array.isArray(review.data?.images) &&
  //   review.data.images[0].image.url !== null
  //     ? review.data.images[0].image
  //     : null
  // const image = firstImage ? (
  //   <GatsbyImage
  //     alt={""}
  //     // objectFit={"contain"}
  //     // width={300}
  //     // height: 200
  //     objectFit={"fullWidth"}
  //     image={firstImage.gatsbyImageData}
  //   />
  // ) : null

  // const contentClass = compact ? testimonialContentCompact : testimonialContent
  // console.log({ review })

  const content = (
    <Card.Meta
      // avatar={
      //   <Avatar
      //     alt={`${review.nickname} avatar`}
      //     src={`https://www.gravatar.com/avatar/${
      //       review.emailHash ?? review.id
      //     }?d=robohash`}
      //     // alt={""}
      //   />
      // }
      title={
        <Space direction={"vertical"}>
          <Rate disabled defaultValue={review.rating} />
          {review.nickname}
        </Space>
      }
      description={
        <span className={testimonialContentCompact}>{review.review}</span>
      }
      // description={
      //   <span className={contentClass}>
      //     <Paragraph>{review.data.review}</Paragraph>
      //   </span>
      // }
    />
  )
  const cover =
    review.img &&
    review.img !== "https://images.loox.io/uploads/2022/2/16/VySKWrUJ2.jpg" ? (
      <div className={testimonialCover}>
        <GatsbyImage
          image={review.imgixImage.gatsbyImageData}
          alt="Review image"
        />
        {/* <img alt="Image submitted with review" src={review.img} /> */}
      </div>
    ) : (
      <div className={testimonialCover}>
        <span style={{ margin: " auto", fontSize: "4em" }} className="ant-rate">
          {review.rating} <StarFilled />
        </span>
      </div>
    )
  return (
    <>
      <Card cover={cover} onClick={showModal} hoverable>
        {content}
      </Card>
      <Modal
        title={
          <Space>
            {review.nickname}
            <Rate disabled defaultValue={review.rating} />
          </Space>
        }
        visible={isModalVisible}
        onOk={hideModal}
        onCancel={hideModal}
        okText={"Close"}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <Space direction="vertical">
          {review.img && (
            <GatsbyImage
              image={review.fullImage.gatsbyImageData}
              alt="Review image"
            />
          )}
          <Typography.Text>{review.review}</Typography.Text>
        </Space>
      </Modal>
    </>
  )
}

export const query = graphql`
  fragment ReviewCard on googleReviewsSheet {
    handle
    email
    #emailHash
    date
    productId
    review
    status
    variant
    img
    orderId
    nickname
    id
    verifiedPurchase
    rating
    imgixImage {
      gatsbyImageData(
        imgixParams: {
          fit: "crop"
          crop: "faces,entropy"
          fill: "blur"
          w: 280
          h: 200
        }
        width: 282
        height: 200
        placeholder: DOMINANT_COLOR
      )
    }
    fullImage: imgixImage {
      gatsbyImageData(
        imgixParams: {
          fit: "fill"
          crop: "faces,entropy"
          fill: "blur"
          #w: 500
          #h: 400
        }
        width: 500
        height: 400
        placeholder: DOMINANT_COLOR
      )
    }
  }
`
