import * as React from "react"
import { graphql, Link, navigate } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import { RichText } from "prismic-reactjs"
import { ProductAnalysis } from "../components/product-analysis"
import { Typography, Row, Col, Breadcrumb, Space, Card } from "antd"
import { Seo } from "../components/seo"
import Layout from "../components/layout"
import { ProductListing } from "../components/product-listing"
import { JsonDebugger } from "../components/json-debugger"

import {
  container,
  reviewSection,
  videoFrame,
} from "./product-page.module.less"
import { htmlSerializer } from "../utils/html-serializer"
import PageContent from "../components/page-content"
import { ReviewCard } from "../components/review-card"
import { Product } from "schema-dts"
import { JsonLd } from "react-schemaorg"
import { sendPageView } from "../utils/analytics"
import { ProductCardContent } from "../components/product-page-card-content"

const { Title } = Typography

// export default function Product({ data: { product, suggestions } }) {
const ProductPage: React.FC<{
  data: any
  location: URL
  path: string
  videoWidth?: number
  videoHeight?: number
}> = (params) => {
  const { data, location, path, videoWidth = 400, videoHeight = 225 } = params
  const urlParams = new URLSearchParams(location.search)
  const sku = urlParams.get("sku")
  const {
    product,
    suggestions,
    shopifyProduct,
    category,
    productTestimonials = { edges: [] },
    otherTestimonials = { edges: [] },
    reviews: reviewEdges = { edges: [] },
  } = data

  if (!shopifyProduct) {
    console.log("No shopify product found")
    return <>Unknown Product</>
  }
  const testimonials = productTestimonials.edges
    .concat(otherTestimonials.edges)
    .map(({ node }) => node)
  const reviews = reviewEdges.edges.map(({ node }) => node)
  const {
    data: {
      product_name: { text: title },
      description,
      directions,
      analysis,
      derived_from,
      video,
      // video: { html: videoHtml } = { html: "" },
    },
  } = product
  const {
    options,
    variants,
    variants: [initialVariant],
    // title,
    images,
  } = shopifyProduct || { variants: [], images: [] }

  const cleanVideoHtml =
    video && video.html
      ? video.html
          // Replace width on html to 400
          .replace(/width="\d+"/, `width="${videoWidth}"`)
          // Remove height on html
          .replace(/height="\d+"/, `height="${videoHeight}"`)
          .replace(/src=/, "loading='lazy' src=")
      : null

  const [variant] = React.useState(
    sku
      ? variants.find(({ sku: vsku }) => vsku === sku) || { ...initialVariant }
      : { ...initialVariant }
  )
  // const [quantity, setQuantity] = React.useState(1)
  const [selectedImage] = React.useState(images[0])

  const variantSelectCallback = (variant) => {
    navigate(`${path}?sku=${variant.sku}`)
  }

  const now = new Date()
  const nextWeek = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000)
  const offers = variants.map((variant) => {
    return {
      "@type": "Offer",
      sku: variant.sku,
      name: variant.displayName,
      itemCondition: "http://schema.org/NewCondition",
      url: `https://dakine420.com${product.url}?sku=${variant.sku}`,
      image: variant.image?.originalSrc,
      price: variant.price,
      gtin: variant.barcode,
      priceValidUntil: nextWeek,
      priceCurrency: "USD",
      availability: variant.availableForSale ? "InStock" : "OutOfStock",
    }
  })
  const review = reviews.length
    ? {
        "@type": "Review",
        reviewRating: {
          "@type": "Rating",
          ratingValue: reviews[0].rating,
          bestRating: "5",
        },
        author: {
          "@type": "Person",
          name: reviews[0].nickname,
        },
        reviewBody: reviews[0].review,
      }
    : null
  const ratingValue = reviews.reduce((p, c, _, a) => p + c.rating / a.length, 0)
  const aggregateRating = {
    "@type": "AggregateRating",
    ratingValue: Math.round((ratingValue + Number.EPSILON) * 100) / 100,
    reviewCount: reviews.length,
  }
  return (
    <Layout>
      {selectedImage ? (
        <Seo
          title={`${title} ${variant.title}`}
          description={RichText.asText(description.richText)}
          image={getSrc(selectedImage.gatsbyImageData)}
        />
      ) : undefined}
      <JsonLd<Product>
        item={{
          "@context": "https://schema.org",
          "@type": "Product",
          name: variant.displayName,
          sku: variant.sku,
          gtin: variant.barcode,
          description: RichText.asText(description.richText),
          image: [getSrc(selectedImage.gatsbyImageData)],
          brand: {
            "@type": "Brand",
            name: "DaKine 420",
          },
          offers,
          review,
          aggregateRating,
          // review: {
          //   "@type": "Review",
          //   reviewRating: {
          //     "@type": "Rating",
          //     ratingValue: "4",
          //     bestRating: "5",
          //   },
          //   author: {
          //     "@type": "Person",
          //     name: "Fred Benson",
          //   },
          // },
          // aggregateRating: {
          //   "@type": "AggregateRating",
          //   ratingValue: "4.4",
          //   reviewCount: "89",
          // },
        }}
      />
      {/* <JsonDebugger json={variant} /> */}
      {/* <JsonDebugger json={{ location, sku, variants, product }} /> */}
      {/* <div className={container}> */}
      {/* <div className={content}> */}
      <PageContent>
        <Space direction="vertical" size="large">
          <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/products/">Products</Link>
            </Breadcrumb.Item>
            {category && (
              <Breadcrumb.Item>
                <Link to={category.url || "/products/unknown/"}>
                  {RichText.asText(category.data.name.richText)}
                </Link>
              </Breadcrumb.Item>
            )}
            <Breadcrumb.Item>{title}</Breadcrumb.Item>
          </Breadcrumb>
          <Card>
            <ProductCardContent
              shopifyProductStorefrontId={shopifyProduct.storefrontId}
              title={title}
              images={images}
              variants={variants}
              initialVariantSku={sku}
              options={options}
              variantSelectCallback={variantSelectCallback}
              videoElement={
                <div
                  dangerouslySetInnerHTML={{ __html: cleanVideoHtml }}
                  className={videoFrame}
                ></div>
              }
            />
          </Card>
          <section>
            {
              description.richText && description.richText.length ? (
                <>
                  <Title level={2}>Description</Title>
                  <RichText
                    render={description.richText}
                    htmlSerializer={htmlSerializer}
                  />
                </>
              ) : null //<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }
          </section>
          <section>
            {
              directions.richText && directions.richText.length ? (
                <>
                  <Title level={2}>Directions</Title>
                  <RichText
                    render={directions.richText}
                    htmlSerializer={htmlSerializer}
                  />
                </>
              ) : null //<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }
          </section>
          <ProductAnalysis analysis={analysis} derivedFrom={derived_from} />

          {reviews.length > 0 && (
            <section className={reviewSection}>
              <Title level={2}>Reviews</Title>
              <Row gutter={[24, 24]}>
                {reviews.map((review, index) => (
                  <Col
                    key={index}
                    lg={{ span: 6 }}
                    md={{ span: 8 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <ReviewCard compact={false} review={review} />
                  </Col>
                ))}
              </Row>
            </section>
          )}

          {/* {testimonials.length > 0 && (
              <section>
                <Title level={2}>Testimonials</Title>
                <div className={testimonialCarouselWrap}>
                  <Carousel
                    slidesToShow={2}
                    autoplay
                    dots
                    ref={(ref) => (testimonialCarousel.current = ref)}
                  >
                    {testimonials.map((testimonial, index) => (
                      <div key={index}>
                        <div className={testimonialCarouselItem}>
                          <TestimonialCard
                            key={index}
                            compact={false}
                            testimonial={testimonial}
                          />
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </div>
                <Link to="/testimonials/">More testimonials</Link>
              </section>
            )} */}
          <Title level={3}>Related Products</Title>
          <ProductListing products={suggestions.products.slice(0, 4)} />
        </Space>

        {/* <JsonDebugger json={testimonials} /> */}
        {/* <JsonDebugger json={reviews} /> */}
        {/* <JsonDebugger json={data} /> */}
        {/* <JsonDebugger json={{ location, sku, variant }} /> */}
        {/* </div> */}
      </PageContent>
      {/* </div> */}
    </Layout>
  )
}
export const productPageQuery = graphql`
  query (
    $id: String!
    $data__product_type__uid: String!
    $data__shopify_product__handle: String!
    $uid: String
  ) {
    productTestimonials: allPrismicTestimonial(
      limit: 4
      filter: {
        data: {
          products: { elemMatch: { product: { uid: { eq: $uid } } } }
          message: { text: { ne: "" } }
          images: { elemMatch: { image: { url: { ne: null } } } }
        }
      } #sort: { #  fields: [data___sort_rating, last_publication_date] #  order: [DESC, DESC] #}
    ) {
      edges {
        node {
          ...TestimonialCard
        }
      }
    }
    otherTestimonials: allPrismicTestimonial(
      limit: 4
      filter: {
        data: {
          products: { elemMatch: { product: { uid: { ne: $uid } } } }
          message: { text: { ne: "" } }
          images: { elemMatch: { image: { url: { ne: null } } } }
        }
      } #sort: { #  fields: [data___sort_rating, last_publication_date] #  order: [DESC, DESC] #}
    ) {
      edges {
        node {
          ...TestimonialCard
        }
      }
    }
    product: prismicProduct(id: { eq: $id }) {
      ...ProductAnalysis
      url
      data {
        product_name {
          text
        }
        theme_color
        description {
          richText
        }
        video {
          html
          title
          embed_url
        }
        directions {
          richText
        }
        product_type {
          uid
        }
        shopify_product {
          id
          title
          product_type
          variants {
            title
            price
            id
            barcode
            sku
            inventory_policy
            inventory_quantity
          }
          options {
            name
            values
            id
          }
          status
        }
        derived_from
      }
    }

    suggestions: allPrismicProduct(
      filter: {
        data: { product_type: { uid: { eq: $data__product_type__uid } } }
        id: { ne: $id }
      }
    ) {
      products: nodes {
        ...ProductCard
      }
    }
    shopifyProduct(handle: { eq: $data__shopify_product__handle }) {
      title
      productType
      tags
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      images {
        id
        src
        thumbnail: gatsbyImageData(
          layout: CONSTRAINED
          width: 120
          aspectRatio: 1
        )
        main: gatsbyImageData(
          layout: CONSTRAINED
          width: 550
          aspectRatio: 1
          placeholder: BLURRED
          backgroundColor: "#ffffff"
        )
      }
      variants {
        availableForSale
        storefrontId
        title
        sku
        price
        barcode
        displayName
        selectedOptions {
          name
          value
        }
        image {
          originalSrc
        }
      }
      options {
        name
        values
        id
      }
    }
    category: prismicProductType(uid: { eq: $data__product_type__uid }) {
      url
      data {
        name {
          richText
        }
      }
    }
    reviews: allGoogleReviewsSheet(
      filter: {
        handle: { eq: $data__shopify_product__handle }
        status: { eq: "Active" }
        rating: { gte: 4 }
      }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...ReviewCard
        }
      }
    }
  }
`
export default ProductPage
